import { useEffect, useState } from "react";
import Game from "../Components/Game";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import BuiltWithWorkplay from "../Components/BuiltWithWorkplay";
import LinkActiveOrExpiredPage from "./LinkActiveOrExpiredPage";
import { useDispatch } from "react-redux";
import { fetchData } from "../api/fetchGameDataAPI";
import { useLocation } from "react-router";
const { REACT_APP_S3_URL } = process.env;

interface PlayProps {}

const Play: React.FC<PlayProps> = ({}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState<number | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  const { title, FaviconUrl } = useSelector(
    (state: RootState) => state.customize,
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gameId = queryParams.get("gameId") || sessionStorage.getItem("gameId");

  useEffect(() => {
    const fetchGameData = async () => {
      if (gameId) {
        try {
          const result = await dispatch(fetchData(gameId));
          if (result) {
            setStatus(result.status);
            setMessage(result.message);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchGameData();
  }, []);

  useEffect(() => {
    setIsMounted(true);
    document.body.style.overflow = "hidden";
    document.title = title;
    const link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = `${REACT_APP_S3_URL}/spin-the-wheel/favicon/${FaviconUrl}`;
    }
  }, []);

  const getMessage = (status: number | null, message: string | null) => {
    if (status === 200) {
      switch (message) {
        case "event yet to start":
          return "This link is not yet active. Please check the project configuration or adjust the dates.";
        case "link currently unavailable":
          return "This link is currently unavailable. Please activate your project to continue.";
        default:
          return "This link is currently unavailable.";
      }
    }

    if (status === 400) {
      switch (message) {
        case "event not available":
          return "This link is no longer available. Renew your plan to restore access.";
        case "event link expired":
          return "This link has expired. Please check the project configuration or adjust the dates.";
        case "invalid link":
          return "Upgrade to gain access to this link.";
        default:
          return "This link is currently unavailable.";
      }
    }

    return "The page you were looking for doesn't exist.";
  };

  return message && status ? (
    <LinkActiveOrExpiredPage message={getMessage(status, message)} />
  ) : (
    <div
      style={{
        opacity: isMounted ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
      }}
      className="flex h-screen flex-col items-center justify-center"
    >
      <Game isCutomize={false} />
      <BuiltWithWorkplay />
      <Toaster />
    </div>
  );
};

export default Play;
